<template>
<v-navigation-drawer app v-model="drawer" :mini-variant="mini" height="100vh" class="vartical-nav" :expand-on-hover="drawerMini" :right="$vuetify.rtl ? true : false">
    <div class="fit_logo_block">

        <v-btn icon @click="mini = !mini" class="menu-switcher" style="margin-left: auto;">
            <svg v-if="mini == false" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="d-none d-lg-block collapse-toggle-icon feather feather-disc">
                <circle cx="12" cy="12" r="10"></circle>
                <circle cx="12" cy="12" r="3"></circle>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="d-none d-lg-block collapse-toggle-icon feather feather-circle">
                <circle cx="12" cy="12" r="10"></circle>
            </svg>
        </v-btn>
    </div>
    <div class="menu-side">
        <!-- <v-list dense nav v-for='navgroup in dash' :key="navgroup.title">
                <v-list-group no-action v-for='subg in navgroup.subgroup' :key="subg.title">
                    <v-list-item slot='activator'>
                        <v-list-item-title>
                            <v-icon>{{ subg.icon }}</v-icon> <span class="menu-single-title">{{subg.title}} </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-for='sub in subg.subLinks' :key="sub.text">

                        <v-list-item-title class="sub-title" :to="sub.route"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle">
                                <circle cx="12" cy="12" r="10"></circle>
                            </svg> {{ sub.text }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>

            </v-list> -->

        <v-list dense nav v-for='navgroup in navgroups' :key="navgroup.title">
            <span class="nav-group-title">
                {{ navgroup.title }}
            </span>
            <span class="nav-group-title-2">
                ...
            </span>
            <div v-for='subg in navgroup.subgroup' :key="subg.title">
                <v-list-group no-action v-if="subg.group">
                    <v-list-item slot='activator'>
                        <v-list-item-title>
                            <v-icon>{{ subg.icon }}</v-icon> <span class="menu-single-title">{{subg.title}} </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-for='sub in subg.subLinks' :key="sub.text" :to="sub.route">

                        <v-list-item-title class="sub-title" :to="sub.route"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle">
                                <circle cx="12" cy="12" r="10"></circle>
                            </svg> {{ sub.text }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-list-item link v-else class="single-item" :to="subg.route">
                    <v-list-item-content>

                        <v-list-item-title>
                            <v-icon>{{ subg.icon }}</v-icon> <span class="menu-single-title">{{subg.title}} </span>
                        </v-list-item-title>
                    </v-list-item-content>

                </v-list-item>

            </div>
        </v-list>
    </div>
</v-navigation-drawer>
<!-- END: Main Menu-->
</template>

<script>
export default {
    data() {
        return {
            drawer: true,
            mini: false,
            dash: [],
            navgroups: [],
            right: null,
        }
    },
    watch: {
        mobiledrawer(data) {

            this.drawer = !this.drawer;

        }
    },
    props: {
        mobiledrawer: Boolean
    },
    computed: {
        drawerMini() {
            if (this.mini == true) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        closeside() {
            this.mini = true;
        },
    },
    mounted() {

        this.navgroups = [{
                subgroup: [{
                    title: "Home",
                    icon: 'mdi-view-dashboard',
                    route: "/"
                }, ]
            },
            {
                subgroup: [{
                    title: "Santander Bank",
                    icon: 'mdi-view-dashboard',
                    route: "/santander"
                }, ]
            },
            {
                subgroup: [{
                    title: "Virgin Bank",
                    icon: 'mdi-view-dashboard',
                    route: "/virgin"
                }, ]
            },

            {
                subgroup: [{
                    title: "Bankline",
                    icon: 'mdi-view-dashboard',
                    route: "/bankline"
                }, ]
            },
            {
                subgroup: [{
                    title: "Revolut",
                    icon: 'mdi-view-dashboard',
                    route: "/revolut"
                }, ]
            },
        ];
    }
}
</script>

<style lang="scss">
$primary-color: #2d245b;
$secoundary_color: #b0bec5;

.vartical-nav {
    background-color: #161d3c !important
}

.menu-side {
    margin-top: 5rem;
    transition: 500ms ease all;

}

.v-navigation-drawer__content {
    transition: 500ms ease all;

    svg {
        //stroke:$primary_color !important
    }

    &::-webkit-scrollbar {
        width: 0.2em;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #aaa;
        border-radius: 20px;
    }
}

.fit_logo_block {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    position: fixed;
    justify-content: space-between;
    width: 100%;
    background: inherit;
    z-index: 3;

    i {
        font-size: 18px;
        padding-left: 55px;
    }
}

.fit_logo {
    display: flex;
    padding: 1rem 0;
    align-items: center;

    .fit_icon {
        height: 24px;
        margin-right: 15px;
    }

    .fit_logo_text {
        height: 20px;
    }
}

.nav-group-title-2 {
    display: none;
}

.v-navigation-drawer--is-mouserover {
    transition: 500ms ease all;
}

.v-navigation-drawer--mini-variant {
    transition: 300ms ease all;

    .nav-group-title,
    .menu-switcher,
    .fit_logo_text,
    .menu-single-title {
        display: none;
    }

    .nav-group-title-2 {
        display: block;
        // color: $primary_color;
        font-size: 20px !important;
        text-align: center;
    }

    .v-list-item__title i {
        margin: 0 !important;
    }

    .v-list {
        padding-bottom: 0;
        padding-top: 0;
    }

    .v-list--nav .v-list-item {
        text-align: center;
    }

    .fit_logo_block {
        justify-content: center;
    }

    .fit_logo .fit_icon {
        margin: 0;
    }
}

.v-list-item--active {
    // .menu-single-title{
    //     color:#fff !important
    // }
}

.v-list--dense .v-list-item .v-list-item__content {
    padding: 8px 5px;
}

.vartical-nav {
    position: fixed !important;
    z-index: 1;
    overflow: hidden;
    transition: 300ms ease all;

    .v-navigation-drawer__border {
        display: none !important;
    }

    .nav-group-title {
        font-size: 12px;
        color: $primary_color;
        letter-spacing: 1px;
        font-weight: bold;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        margin-bottom: 0.2rem;

    }

    .v-navigation-drawer--mini-variant {
        .nav-group-title {
            display: none !important;
        }
    }

    .v-list {
        padding-left: 1rem;
        padding-right: 1rem;

    }

    .v-list-item__title {
        font-size: 15px !important;
        color: $secoundary_color !important;
        text-transform: capitalize !important;

        i {
            font-size: 17px;
            color: $secoundary_color;
            margin-right: 8px !important;
        }
    }

    .sub-title {
        font-size: 14px !important;
        color: $secoundary_color;

    }

    a.v-list-item--active {
        background-color: $primary_color;
        color: #fff !important;

        .v-list-item__title,
        .menu-single-title {
            color: #fff !important;
        }

        i {
            color: #fff;
        }
    }

    .v-list-group__items {
        margin-top: -5px;

        .v-list-item {
            padding-left: 25px !important;

            &:hover {
                margin-left: 5px !important;
                transition: 300ms ease-in-out;

            }
        }
    }

    .v-list-group__header,
    .v-list-item {
        transition: 300ms ease-in-out;
        padding: 0 !important;
    }

    .v-list-group__header:hover .v-list-item,
    .single-item:hover {
        margin-left: 5px !important;
        transition: 300ms ease-in-out;
    }

    .sub-title {
        cursor: pointer;
        transition: 300ms ease-in-out;

    }
}
</style>
